<template>
	<div @wheel="goWheel" class="position-relative">
		<div class="position-absolute left-0 right-0 bottom-0 flex align-center justify-center mb-5"
			style="z-index: 99999;">
			<div class="text-center  animated pulse text-white infinite cursor-pointer-main" @click="nextPrv">
				<span class="iconfont icon-shubiao font28  cursor-pointer-main"></span>
				<div class=" mt-1 font12 cursor-pointer-main">向下滑动继续探索尚恰</div>
			</div>
		</div>
		<el-carousel height="100vh" direction="vertical" :autoplay="false" trigger="click" ref="swiper" :loop="false"
			arrow="always" @change="change" @setActiveItem="setActiveItem">
			<!-- 第一屏 -->
			<el-carousel-item>
				<nav-list :logo="require('@/assets/images/logo.png')" classType="text-white " :resData="navList"
					:one='one' :tabIndex="tabIndex" @changeTab="changeTab"></nav-list>
				<div class="w-1400 mx-auto">
					<div style="margin-top: 20vh;">
						<div class="font50 main-text font-weight-bold text-left ">尚恰实业</div>
						<div class="mt-15 font50 text-white text-left ">用互联网思维为传统产业助跑</div>
						<div class="mt-15 font26 text-white text-left ">致力于成为智慧钢贸行业的领军企业</div>
						<div class="mt-4 flex align-center  ">
							<div class="main-bg text-white rounded-circle font16 px-3 py-1 flex align-center">
								<span>了解更多内容</span>
								<i class="el-icon-caret-right ml"></i>
							</div>
						</div>
					</div>

				</div>
			</el-carousel-item>
			<!-- 第二屏 -->
			<el-carousel-item>
				<nav-list :logo="require('@/assets/images/img_logo.jpg')" classType="text-dark" :resData="navList"
					:one='one' :tabIndex="tabIndex" @changeTab="changeTab"></nav-list>
				<div class="w-1400 mx-auto text-left">
					<div class="mt-5">
						<div class="font40 main-text font-weight-bold">ABOUT US</div>
						<div class="font-weight-bold font26 mt-1">尚恰实业有限公司</div>
						<div style="width: 920px;" class="mt-1 line2 font14">
							<div>
								尚恰实业创立于 2012 年，是中国领先的钢材贸易服务商。利用 自身在钢材贸易服务的经验，开拓出钢材销售、加工、施工等一体化 产业链。
							</div>

							<div class="mt-15">
								公司以“质量第一，客户第一，诚信第一”的经营理念，赢得了 客户以及诸多厂家和供应商的信赖。现已与中铁四局集团有限公司、 中铁一局集团有限公司、中铁二局集团有限公司、中铁物贸集团有限 公司、中铁建工集团有限公司、中铁四局集团物资工贸有限公司、中 铁一局桥梁工程有限公司、四川省港航投资集团有限责任公司、四川 省能源投资集团有限责任公司、中铁十四局集团第三工程有限公司、 中铁四局集团有限公司工程建设分公司、中铁四局集团机电设备安装 有限公司钢结构分公司、中铁十四局集团第三工程有限公司、中铁十 八局集团隧道工程有限公司、中铁一局集团桥梁工程有限公司、中铁 电气化局集团有限公司铁路工程公司、上海隧道工程智造海盐有限公 司等国内知名大型国有企业建立了长期良好的合作关系。
							</div>
							<div class="mt-15">
								2022 年，尚恰实业秉承“互联网+”的理念，精心打造大宗商品 交易管理平台，以产品和技术助力全行业管理升级，推动数字化、网 络化、智能化。 真诚携手，共赢同享，尚恰实业有限公司愿与您携 手共进，开创未来、实现梦想。
							</div>

						</div>
						<div style="width: 800px;" class="mt-3 flex align-center justify-between">
							<div class="flex-1">
								<div class="flex align-end main-text">
									<div class="font-weight-bold font40">2012</div>
									<div class="font-weight-bold font20 mb ml">年</div>
								</div>
								<div class="font14">成立时间</div>
							</div>

							<div class="flex-1">
								<div class="flex align-end main-text">
									<div class="font-weight-bold font40">300</div>
									<div class="font-weight-bold font20 mb ml">万+</div>
								</div>
								<div class="font14">服务客户数</div>
							</div>
							<div class="flex-1">
								<div class="flex align-end main-text">
									<div class="font-weight-bold font40">70</div>
									<div class="font-weight-bold font20 mb ml">+</div>
								</div>
								<div class="font14">覆盖城市</div>
							</div>
							<div class="flex-1">
								<div class="flex align-end main-text">
									<div class="font-weight-bold font40">1000</div>
									<div class="font-weight-bold font20 mb ml">万+</div>
								</div>
								<div class="font14">企业注册资金</div>
							</div>

						</div>
						<div class="mt-3 flex align-center ">
							<div class="main-bg rounded-circle px-2 py-1 text-white">查看更多</div>
						</div>

					</div>
				</div>
			</el-carousel-item>
			<!-- 第三屏 -->
			<el-carousel-item>
				<div class="flex flex-column" style="height: 100vh;">
					<div class="bg-3-screen  flex-shrink">
						<nav-list :logo="require('@/assets/images/logo.png')" classType="text-white" :resData="navList"
							:one='one' :tabIndex="tabIndex" @changeTab="changeTab"></nav-list>
					</div>
					<div class="flex-1 flex align-stretch">
						<div
							class="flex-1  border-right bg-3-screen-border flex flex-column justify-between  bg-3-screen9">
							<div class="mx-3 px-3 flex-1 text-left c-d-none" style="padding-top:15% ;">
								<img src="../assets/images/icon_erp.png" style="width: 40px;height: 37px;" />
								<div class="font24 text-white mt-1 font-weight-bold">进销存ERP</div>
								<div class="text-white font14 line2 mt-1">进销存系统是钢材企业管理中必不可少的工具，它涵盖了采购、销售、库存和财务管理等功能
								</div>
							</div>
							<div class="mx-3 px-3 flex-1 text-left c-d-none" style="">
								<div class="flex align-center">
									<div class="bg-white-opacity85" style="width:34px ;height: 2px;"></div>
									<div class="font14 font-weight400 ml-1 text-white">TYL ERP</div>
								</div>
								<div style="font-size: 200px; color: rgba(255, 255, 255, 0.05);" class="font-weight500">
									01</div>
							</div>
							<div class="c-d-block mx-5">
								<div style="margin-top:15% ;box-shadow: -20px -20px 0px #A17754;"><img
										src="@/assets/images/erp.jpg" class="imgwidth" /></div>
								<div class="mt-15 font26 font-weight500 text-left text-white">进销存ERP</div>
								<div class=" text-ellipsis-3 line2 mt-1 font14 text-white text-left">
									进销存系统是钢材企业管理中必不可少的工具，它涵盖了采购、销售、库存和财务管理等功能。通过实时监控库存、订单和供应商管理，有助于优化供应链、提高效率并降低成本。
								</div>
								<div class="flex align-center flex-wrap">
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">采购管理</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">销售管理</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">库存管理</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">供应商管理</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">客户管理</div>
								</div>
								<div class="flex align-center justify-between mt-5">
									<div class="border border-white bg-white-opacity15 text-white flex-1 mr line23 text-center py" @click="tylerp">
										了解更多</div>
									<div class="bg-white  main-text flex-1 ml line23 text-center py" @click="tylerp">立即试用</div>
								</div>
							</div>
						</div>
						<div
							class="flex-1 bg-3-screen9 border-right bg-3-screen-border flex flex-column justify-between">

							<div class="mx-3 px-3 flex-1 text-left c-d-none" style="padding-top:15% ;">
								<img src="../assets/images/icon_mall.png" style="width: 40px;height: 37px;" />
								<div class="font24 text-white mt-1 font-weight-bold">铁云链商城</div>
								<div class="text-white font14 line2 mt-1 text-left">以钢铁贸易为核心的电子商务平台，提供全方位的钢铁产品信息和交易服务。
								</div>
							</div>
							<div class="mx-3 px-3 flex-1 text-left c-d-none" style="">
								<div class="flex align-center">
									<div class="bg-white-opacity85" style="width:34px ;height: 2px;"></div>
									<div class="font14 font-weight400 ml-1 text-white">TYL MALL </div>
								</div>
								<div style="font-size: 200px; color: rgba(255, 255, 255, 0.05);" class="font-weight500">
									02</div>
							</div>
							<div class="c-d-block mx-5">
								<div style="margin-top:15% ;box-shadow: -20px -20px 0px #A17754;"><img
										src="@/assets/images/mall.jpg" class="imgwidth" /></div>
								<div class="mt-15 font26 font-weight500 text-left text-white">铁云链商城</div>
								<div class=" text-ellipsis-3 line2 mt-1 font14 text-white text-left">
									以钢铁贸易为核心的电子商务平台，提供全方位的钢铁产品信息和交易服务。</div>
								<div class="flex align-center flex-wrap">
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">快速采购</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">厂家直销</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">在线支付</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">实时轨迹</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">一票制</div>
								</div>
								<div class="flex align-center justify-between mt-5">
									<div class="border border-white bg-white-opacity15 text-white flex-1 mr line23 text-center py" @click="tylmall">了解更多</div>
									<div class="bg-white  main-text flex-1 ml line23 text-center py" @click="tylmall">立即试用</div>
								</div>
							</div>

						</div>
						<div
							class="flex-1 bg-3-screen9 border-right bg-3-screen-border flex flex-column justify-between">
							<div class="mx-3 px-3 flex-1 text-left c-d-none " style="padding-top:15% ;">
								<img src="../assets/images/icon_wuliu.png" style="width: 38px;height: 41px;" />
								<div class="font24 text-white mt-1 font-weight-bold">铁云链物流平台</div>
								<div class="text-white font14 line2 mt-1">平台为钢铁行业提供专业、智能、高效的仓储物流服务，实现资源优化配置，提升物流效率和降低成本。
								</div>
							</div>
							<div class="mx-3 px-3 flex-1 text-left c-d-none" style="">
								<div class="flex align-center">
									<div class="bg-white-opacity85" style="width:34px ;height: 2px;"></div>
									<div class="font14 font-weight400 ml-1 text-white">TYL LOGISTICS </div>
								</div>
								<div style="font-size: 200px; color: rgba(255, 255, 255, 0.05);" class="font-weight500">
									03</div>
							</div>
							<div class="c-d-block mx-5">
								<div style="margin-top:15% ;box-shadow: -20px -20px 0px #A17754;"><img
										src="@/assets/images/wuliu.jpg" class="imgwidth" /></div>
								<div class="mt-15 font26 font-weight500 text-left text-white">铁云链物流平台</div>
								<div class=" text-ellipsis-3 line2 mt-1 font14 text-white text-left">
									平台为钢铁行业提供专业、智能、高效的仓储物流服务，实现资源优化配置，提升物流效率和降低成本。</div>
								<div class="flex align-center flex-wrap">
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">发布需求</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">在线接单</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">实时轨迹</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">快速结算</div>
								</div>
								<div class="flex align-center justify-between mt-5">
									<div
										class="border border-white bg-white-opacity15 text-white flex-1 mr line23 text-center py" @click="tylwl">
										了解更多</div>
									<div class="bg-white  main-text flex-1 ml line23 text-center py" @click="tylwl">立即试用</div>
								</div>
							</div>
						</div>
						<div class="flex-1 bg-3-screen9  flex flex-column justify-between">
							<div class="mx-3 px-3 flex-1 text-left c-d-none " style="padding-top:15% ;">
								<img src="../assets/images/icon_jinrong.png" style="width: 38px;height: 37px;" />
								<div class="font24 text-white mt-1 font-weight-bold">铁云链金融</div>
								<div class="text-white font14 line2 mt-1">钢铁行业提供专业、全面的金融服务，包括供应链金融、白条、贴票等服务，解决中小企业融资难问题。
								</div>
							</div>
							<div class="mx-3 px-3 flex-1 text-left c-d-none" style="">
								<div class="flex align-center">
									<div class="bg-white-opacity85" style="width:34px ;height: 2px;"></div>
									<div class="font14 font-weight400 ml-1 text-white">TYL FINANCE</div>
								</div>
								<div style="font-size: 200px; color: rgba(255, 255, 255, 0.05);" class="font-weight500">
									04</div>
							</div>
							<div class="c-d-block mx-5">
								<div style="margin-top:15% ;box-shadow: -20px -20px 0px #A17754;"><img
										src="@/assets/images/jinrong.jpg" class="imgwidth" /></div>
								<div class="mt-15 font26 font-weight500 text-left text-white">铁云链金融</div>
								<div class=" text-ellipsis-3 line2 mt-1 font14 text-white text-left">
									钢铁行业提供专业、全面的金融服务，包括供应链金融、白条、贴票等服务，解决中小企业融资难问题。</div>
								<div class="flex align-center flex-wrap">
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">铁云链白条</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">承兑票据</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">便捷高效</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">安全合规</div>
									<div class="mt-1 font12 px-15 py border border-white text-white mr-1">覆盖面管</div>
								</div>
								<div class="flex align-center justify-between mt-5">
									<div
										class="border border-white bg-white-opacity15 text-white flex-1 mr line23 text-center py" @click="tyljr">
										了解更多</div>
									<div class="bg-white  main-text flex-1 ml line23 text-center py" @click="tyljr">立即试用</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</el-carousel-item>
			<!-- 第四屏 -->
			<el-carousel-item>
				<div class="bg-3-screen ">
					<nav-list :logo="require('@/assets/images/logo.png')" classType="text-white" :resData="navList"
						:one='one' :tabIndex="tabIndex" @changeTab="changeTab"></nav-list>
				</div>
				<div class="w-1400 mx-auto flex align-stretch" style="margin-top: 5%;">
					<div class="flex-shrink text-left mr-3" style="width: 330px;">
						<div class="font24 text-white">全国版图多元化业务全面拓展</div>
						<div class="font12 mt-15 text-white line2">
							全国版图拓展，多元化业务全面开花，钢贸互联网公司以创新技术和高效服务为核心，推动钢铁贸易行业的数字化发展，为客户提供全方位的解决方案。</div>
						<div class="mt-4">
							<el-row :gutter="5">
								<el-col :span="12">
									<div class="bg-4-list mt">
										<div class="text-center py-15 c-none">
											<img src="@/assets/images/icon_jiancaigongying.png"
												style="width: 107px;height: 109px;" />
											<div class="mt-1 text-white font14">建材供应</div>
										</div>
										<div class=" p-15 c-block text-left">
											<div class="font-weight-bold text-white mt-1 font16">建材供应</div>
											<div class="mt-1 text-white font14 text-ellipsis-3">产品涵盖型材、钢板、螺纹钢、 钢绞线及各类建材钢材。</div>
											<div class="mt-2 flex align-center">
												<div class="font12 bg-white rounded-circle main-text px-1 line2">了解更多</div>
											</div>
										</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="bg-4-list mt">
										<div class="text-center py-15 c-none">
											<img src="@/assets/images/icon_jiagong.png"
												style="width: 107px;height: 109px;" />
											<div class="mt-1 text-white font14">生产加工</div>
										</div>
										<div class=" p-15 c-block text-left">
											<div class="font-weight-bold text-white mt-1 font16">生产加工</div>
											<div class="mt-1 text-white font14 text-ellipsis-3">委托加工加工费用和货款一票结算客户省时、省心。</div>
											<div class="mt-2 flex align-center">
												<div class="font12 bg-white rounded-circle main-text px-1 line2">了解更多</div>
											</div>
										</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="bg-4-list mt">
										<div class="text-center py-15 c-none">
											<img src="@/assets/images/icon_ccwuliu.png"
												style="width: 107px;height: 109px;" />
											<div class="mt-1 text-white font14">仓储物流</div>
										</div>
										<div class=" p-15 c-block text-left">
											<div class="font-weight-bold text-white mt-1 font16">仓储物流</div>
											<div class="mt-1 text-white font14 text-ellipsis-3">运输、仓储、加工一站式搞定所有物流需求。</div>
											<div class="mt-2 flex align-center">
												<div class="font12 bg-white rounded-circle main-text px-1 line2">了解更多</div>
											</div>
										</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="bg-4-list mt">
										<div class="text-center py-15 c-none">
											<img src="@/assets/images/icon_jinrongfuwu.png"
												style="width: 107px;height: 109px;" />
											<div class="mt-1 text-white font14">金融服务</div>
										</div>
										<div class=" p-15 c-block text-left">
											<div class="font-weight-bold text-white mt-1 font16">金融服务</div>
											<div class="mt-1 text-white font14 text-ellipsis-3">致力于帮助用户在平台采购钢材时，更方便、更划算的使用承兑票据进行支付结算</div>
											<div class="mt-2 flex align-center">
												<div class="font12 bg-white rounded-circle main-text px-1 line2">了解更多</div>
											</div>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
					<div class="flex-1">
						<div class="px-3"><img src="@/assets/images/img_ditu.png" class="imgwidth" /></div>
					</div>
					<div class="flex-shrink flex flex-column ml-5" style="width: 200px;">
						<div class="flex-1 text-white">
							<div class="text-center">
								<img src="@/assets/images/icon_city.png" style="width: 62px;height: 62px;" />
								<div class="font16 mt-1">服务城市</div>
								<div class="flex align-end justify-center mt-1">
									<div class="font-weight-bold font30">200</div>
									<div class="font-weight-bold font20 mb ml">+</div>
								</div>
							</div>
						</div>
						<div class="flex-1 text-white">
							<div class="text-center">
								<img src="@/assets/images/icon_yonghu.png" style="width: 62px;height: 62px;" />
								<div class="font16 mt-1">累计用户</div>
								<div class="flex align-end justify-center mt-1">
									<div class="font-weight-bold font30">3</div>
									<div class="font-weight-bold font20 mb ml">万+</div>
								</div>
							</div>
						</div>
						<div class="flex-1 text-white">
							<div class="text-center">
								<img src="@/assets/images/icon_yonghu.png" style="width: 62px;height: 62px;" />
								<div class="font16 mt-1">服务企业</div>
								<div class="flex align-end justify-center mt-1">
									<div class="font-weight-bold font30">50</div>
									<div class="font-weight-bold font20 mb ml">+</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-carousel-item>
			<!-- 第五屏 -->
			<el-carousel-item>
				<div class="flex flex-column" style="height: 100vh;">
					<div class="flex-shrink">
						<nav-list :logo="require('@/assets/images/img_logo.jpg')" classType="text-dark"
							:resData="navList" :one='one' :tabIndex="tabIndex" @changeTab="changeTab"></nav-list>
					</div>
					<div class="flex-1 flex align-stretch">
						<div class="bg-5-screen flex align-center justify-center p-5">
							<div style="width: 400px;" class="mx-auto text-left">
								<div class="main-text font-weight600 font30">COMPANY DYNAMICS</div>
								<div class="font-weight600 font30 mt-1">企业动态</div>
								<div class="font14 mt-1">实时更新尚恰最新动态，了解尚恰文化，了解尚恰进步。</div>
								<div class="mt-3 flex align-center ">
									<div class="main-bg rounded px-2 py-1 text-white">查看更多</div>
								</div>
								<div class="mt-5 pt-5 flex align-center">
									<div class="main-bg text-white rounded-circle text-center"
										style="width: 45px;height: 45px;line-height: 45px;"><i
											class="el-icon-arrow-left font16"></i></div>
									<div class="bg-3-screen text-white rounded-circle text-center ml"
										style="width: 45px;height: 45px;line-height: 45px;"><i
											class="el-icon-arrow-right font16"></i></div>
								</div>
							</div>
						</div>
						<div class="flex-1 flex align-stretch">
							<div class="w-33 border-right flex flex-column">
								<div class="flex-1 flex align-center ">
									<div class="pl-4 font60 font-weight-bold" style="color: #F0E7DB;">6</div>
								</div>
								<div class="flex-shrink pl-4 text-left">
									<div class="main-text font-weight-bold font16">2024.2</div>
									<div class="text-ellipsis-1 font-weight500 font18 mt-1">尚恰实业春节放假通知</div>
									<div style="width: 24px;height: 3px;" class="main-bg rounded mt-1"></div>
									<div class="font14 text-light-muted mt-1">来源：尚恰实业</div>
									<div class="mt-1"><img src="@/assets/images/pro2.jpg" class="imgwidth" /></div>
									<div class="text-ellipsis-2 mt-1 font14 line2">根据国家法定节假日规定，结合公司实际情况，我司将于2024年2月7日至17日放假调休，共11天。2月18日（星期日）正常上班。</div>
								</div>
								<div class="flex-1">
									<div class="mt-3 flex align-center pl-4">
										<span class="font14 text-light-muted cursor-pointer-main">查看全部</span>
										<i class="el-icon-caret-right main-text ml font16"></i>
									</div>
								</div>
							</div>
							<div class="w-33 border-right flex flex-column">
								<div class="flex-1 flex align-center ">
									<div class="pl-4 font60 font-weight-bold" style="color: #F0E7DB;">19</div>
								</div>
								<div class="flex-shrink pl-4 text-left">
									<div class="main-text font-weight-bold font16">2023.12</div>
									<div class="text-ellipsis-1 font-weight500 font18 mt-1">19日晋南线盘市场价格持稳 市场成交一般</div>
									<div style="width: 24px;height: 3px;" class="main-bg rounded mt-1"></div>
									<div class="font14 text-light-muted mt-1">来源：尚恰官网</div>
									<div class="mt-1"><img src="@/assets/images/pro.jpg" class="imgwidth" /></div>
									<div class="text-ellipsis-2 mt-1 font14 line2">19日晋南地区钢厂线材价格指数3921元/吨，较前一交易日价格持稳，盘螺价格指数为3916.2元/吨，较前一交易日价格持稳</div>
								</div>
								<div class="flex-1">
									<div class="mt-3 flex align-center pl-4">
										<span class="font14 text-light-muted cursor-pointer-main">查看全部</span>
										<i class="el-icon-caret-right main-text ml font16"></i>
									</div>
								</div>
							</div>
							<div class="w-33 border-right flex flex-column">
								<div class="flex-1 flex align-center ">
									<div class="pl-4 font60 font-weight-bold" style="color: #F0E7DB;">09</div>
								</div>
								<div class="flex-shrink pl-4 text-left">
									<div class="main-text font-weight-bold font16">2023.12</div>
									<div class="text-ellipsis-1 font-weight500 font18 mt-1">12月份上海建筑钢材价格或冲高回落</div>
									<div style="width: 24px;height: 3px;" class="main-bg rounded mt-1"></div>
									<div class="font14 text-light-muted mt-1">来源：尚恰官网</div>
									<div class="mt-1"><img src="@/assets/images/pro3.jpg" class="imgwidth" /></div>
									<div class="text-ellipsis-2 mt-1 font14 line2">2023年11月上海建筑钢材价格偏强运行。上半月宏观利好叠加刚性需求和投机需求纷纷入场，现货价格量价齐升，不过后半月终端需求对高位资源接受稍显乏力，需求持续性不佳，价格出现小幅回调。截至月末，极差为250元/吨，环比2023年10月震荡区间明显扩大</div>
								</div>
								<div class="flex-1">
									<div class="mt-3 flex align-center pl-4">
										<span class="font14 text-light-muted cursor-pointer-main">查看全部</span>
										<i class="el-icon-caret-right main-text ml font16"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-carousel-item>
			<!-- 第六屏 -->
			<el-carousel-item>
				<div class="bg-3-screen ">
					<nav-list :logo="require('@/assets/images/logo.png')" classType="text-white" :resData="navList"
						:one='one' :tabIndex="tabIndex" @changeTab="changeTab"></nav-list>
				</div>
				<div class="w-1400 mx-auto flex align-stretch" style="margin-top: 5%;">
					<div class="flex-shrink text-left mr-3" style="width: 330px;">
						<div class="font-weight500 text-muted font24">
							COOPERATIVE PARTNER
						</div>
						<div class="font24 text-white mt-15">合作伙伴</div>
						<div class="font12 mt-15 text-white line2">
							在未来的征程中，我们将继续秉持合作共赢的理念，与更多优秀的合作伙伴建立广泛、深入的合作关系。通过共享资源、互利共赢，共同开创一个繁荣、可持续发展的互联网钢贸生态圈。</div>
						<div class="mt-3 flex align-center ">
							<div class="main-bg rounded px-2 py-1 text-white">交流合作</div>
						</div>

					</div>
					<div class="flex-1">
						<div class="px-3 pb-3"><img src="@/assets/images/img_logobg.png" class="imgwidth" /></div>
					</div>

				</div>
			</el-carousel-item>
			<!-- 第七屏 -->
			<el-carousel-item >
				<nav-list :logo="require('@/assets/images/img_logo.jpg')" classType="text-dark " :resData="navList"
					:one='one' :tabIndex="tabIndex" @changeTab="changeTab"></nav-list>
				<div class="bg_join-us flex align-center justify-center " style="height: 62vh;">
					<div class="mx-auto w-1400 text-left">
						<div class="font-weight-bold main-text font30">同舟共济，尚恰与你共赢未来</div>
						<div class="text-muted mt-1 font14">如果您有合作意向，可以留下您的信息，我们会在最短的时间和您联系</div>
						<div class="mt-3">
							<div class="font14 flex align-center">
								<span class="text-danger">*</span>
								<div class="ml">您的姓名</div>
							</div>
							<el-input placeholder="请输入您的姓名" style="width: 300px;" class="border-0 mt-1"></el-input>
							<div class="font14 flex align-center mt-15">
								<span class="text-danger">*</span>
								<div class="ml">您的电话</div>
							</div>
							<el-input placeholder="请输入您的联系方式" style="width: 300px;" class="border-0 mt-1"
								type="number"></el-input>
						</div>
					</div>
				</div>
				<div class="flex align-stretch  w-1400 mx-auto pt-2">
					<div class="flex align-start">
						<div class="flex-shrink mr-15">
							<div class="text-left"><img src="@/assets/images/bottom_icon_logo.jpg"
									style="width:142px ;height:45px ;" /></div>
							<div class="mt-15 flex align-center">
								<div class="text-center mr-1">
									<img src="@/assets/images/gzh.jpg" style="width: 70px;height: 70px;" />
									<div class="mt-1">公众号</div>
								</div>
								<div class="text-center mr-1">
									<img src="@/assets/images/ewm.png" style="width: 70px;height: 70px;" />
									<div class="mt-1">APP下载</div>
								</div>
							</div>
						</div>
						<div class="text-left">
							<div class="font14 text-light-muted">全国统一服务热线</div>
							<div class="font14 font-weight500 mt">029-85799222</div>
							<div class="font14 text-light-muted mt-1">公司地址</div>
							<div class="font14 font-weight500 mt">陕西省西安市新城区石家街东岸国际2008</div>
							<div class="font14 text-light-muted mt-1">电子邮箱</div>
							<div class="font14 font-weight500 mt">helei@tieyunlian.com</div>
						</div>
					</div>
					<div class="flex-1 flex flex-column justify-between">
						<div class="flex align-center justify-end mt-15">
							<div v-for="(item,index) in navList" class="ml-15" :key="index">{{item.name}}</div>
						</div>
						<div class="  mb-15">
							<div class="flex align-center justify-end">
								<div class="ml-1"> 尚恰实业有限公司 ©2023 </div>
								<div class="ml-1 text-light-muted">|</div>
								<div class="ml-1 cursor-pointer-main" @click="tobenan">陕ICP备2022005887号-1</div>
								<div class="ml-1 text-light-muted">|</div>
								<div class="ml-1"> 技术支持：西安铁云链电子商务有限公司</div>
							</div>
							<div class="flex align-center justify-end" style=" padding:10px 0;text-align: center;">
							  <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61010202000278"
							    style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
							      src="@/assets/images/gaban.png" style="float:left;" />
							    <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">陕公网安备
							      61010202000278号</p>
							
							  </a>
							  <div class="ml-1" style="color:#939393;">增值电信业务经营许可证陕B2-202200339 </div>
							</div>
						</div>
						
					</div>
				</div>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	import navList from '@/components/navList.vue'
	export default {
		components: {
			navList
		},
		name: 'HomeView',
		data() {
			return {
				one: true,
				tabIndex: 0,
				navList: [{
						name: "网站首页",
						path: '/'
					},
					{
						name: "关于尚恰",
						path: '/'
					},
					{
						name: "产品与服务",
						path: '/'
					},
					{
						name: "经营业务",
						path: '/'
					},
					{
						name: "企业动态",
						path: '/'
					},
					{
						name: "合作伙伴",
						path: '/'
					},
					{
						name: "加入我们",
						path: '/'
					},
					// {
					// 	name: "联系我们",
					// 	path: '/'
					// },

				]
			}
		},
		methods: {
			tyljr(){
				 window.open('https://mall.tieyunlian.com/finance', '_blank')
			},
			tylmall(){
				 window.open('https://www.tieyunlian.com/', '_blank')
			},
			tylerp(){
				 window.open('https://steel.tieyunlian.com/', '_blank')
			},
			tylwl(){
				 window.open('https://wuliu.tieyunlian.com/', '_blank')
			},
			tobenan(){
				window.open('https://beian.miit.gov.cn', '_blank')
			},
			goWheel() {
				if (event.deltaY > 0 && this.one == true) { //data中定义one为true 当one为true时执行
					this.$refs.swiper.next(); //以此来控制每次轮播图切换的张数
					this.one = false;
					setTimeout(() => {
						this.one = true
					}, 1000)
				}
				if (event.deltaY < 0 && this.one == true) {
					this.$refs.swiper.prev();
					this.one = false;
					setTimeout(() => {
						this.one = true
					}, 1000)
				}
			},
			nextPrv() {
				this.$refs.swiper.next()
			},
			change(e) {
				this.tabIndex = e
			},
			changeTab(index) {
				if (this.tabIndex === index) {
					return;
				}
				this.tabIndex = index;
				this.setActiveItem(index);
			},
			setActiveItem(index) {
				this.$refs.swiper.setActiveItem(index);
			},
		}
	}
</script>
<style scoped>
	.el-carousel__item:nth-child(1) {
		background: url("@/assets/images/bg_home.jpg") no-repeat top center;
		background-size: cover
	}

	.el-carousel__item:nth-child(2) {
		background: url("@/assets/images/bg_aboutus.jpg") no-repeat bottom center;
		background-size: cover
	}

	.el-carousel__item:nth-child(3) {
		background: url("@/assets/images/bg_chanpinfuwu.jpg") no-repeat top center;
		background-size: cover
	}

	.el-carousel__item:nth-child(4) {
		background: url("@/assets/images/bg_yewu.jpg") no-repeat top center;
		background-size: cover
	}

	.el-carousel__item:nth-child(5) {
		background-color: #ffffff;
	}

	.el-carousel__item:nth-child(6) {
		background: url("@/assets/images/bg_hezuo.jpg") no-repeat top center;
		background-size: cover
	}

	.bg-5-screen {
		background: url("@/assets/images/bg_news.jpg") no-repeat bottom center;
		background-size: cover
	}

	.el-carousel__item:nth-child(7) {
		background-color: #ffffff;
	}

	.bg_join-us {
		background: url("@/assets/images/bg_join-us.jpg") no-repeat bottom center;
		background-size: cover
	}

	.bg-3-screen9:hover {
		opacity: 0.9;
		/* 透明度 */
		transition: 2s;
		/* //动画过渡的更加顺滑 */
		background: linear-gradient(180deg, #C39F6D 0%, #A6824F 100%);
		cursor: pointer;
	}

	.bg-3-screen9:hover .c-d-none {
		display: none;
	}

	.bg-3-screen9 .c-d-block {
		display: none;
	}

	.bg-3-screen9:hover .c-d-block {
		display: block;
	}

	::v-deep .el-carousel__indicator--vertical .el-carousel__button {
		width: 10px;
		height: 10px;
		border-radius: 10px;
	}

	::v-deep .el-input__inner {
		border: 0
	}
</style>
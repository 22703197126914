<template>

	<div class="flex align-center justify-between w-1400 mx-auto" style="padding: 15px 0; ">
		<img :src="logo" style="width:173px ;height: 43px;" :class="one?'':'animated fadeInLeft'" />
		<div class="flex align-center justify-end" :class="one?'':'animated fadeInRight'">
			<div class="ml-2 font14  cursor-pointer-main "
				:class="tabIndex==index?'main-text font-weight-bold':classType" @click="changeTab(index)"
				v-for="(item,index) in resData" :key='index'>{{item.name}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			logo: String,
			resData: Array,
			classType: String,
			one: Boolean,
			tabIndex: Number
		},
		methods: {
			changeTab(index) {
				this.$emit("changeTab", index)
			},
		}
	}
</script>

<style>
</style>